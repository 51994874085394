.search:focus-within {
  border-color: #2684FF;
  box-shadow: 0 0 0 1px #2684FF;
  outline: 0;
  -webkit-appearance: none;
}

.search input:focus {
  outline: 0;
  -webkit-appearance: none;
}

.tag {
  background-color: #6c757d;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 6px;
}
